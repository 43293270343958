import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import GiftCards from './GiftCards'
import Spirits from './Spirits'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/", "/menu", "/order"]

export const pages = [
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu" },
  // {path: "/gift-cards", label: "Gift Cards", component: GiftCards },
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">

        <div
          className="hero-banner">
          <div className="text">
            {/* <div className="hero">
              <img src={heroUrl} alt=""/>
            </div> */}
            <div className="tag-line">
              <h2 style={{
              }}>
                 <img
                   className="icon"
                   style={{
                     width: "200px"
                   }}
                   src={`https://afag.imgix.net/dads-donut-and-bakery/logo-5.png?w=400`} alt=""/>
              </h2>

              <p
                style={{
                  textAlign: 'center'
                }}>
                <Link to="/order" className="order-online-button">Order ToGo / Delivery</Link>
                {/* <Link to="/order" className="order-online-button">Book a Cooking Class</Link> */}
              </p>
            </div>
            {/* <p className="small">with 5% rewards</p> */}


          </div>

        </div>



        {/* <div className="menu">

          <div className="food-gallery">
            <div className="photo">
              <Link to="/menu">View Menu</Link>
            </div>
            {foodImages.map(({url}) => {
              return(
                <div className="photo">
                  <img src={`${url}?w=300&h=300&fit=crop`} alt=""/>
                </div>
              )
            })}
          </div>
        </div> */}

        {/* <div className="events">

        </div> */}

{/*
        <div className="features">

          {sections.map(({h1, sub, img, content, link, linkText, className=""}) => {
            return(
              <section className={"feature-section" + className}>
                <div className="photo">
                  <img src={img} alt=""/>
                </div>
                <div className="content">
                  <h3>{h1}</h3>
                  <div className="text">
                    <p>{content}</p>
                  </div>
                  <div className="more-content">
                    <Link className="link" to={link}>{linkText}</Link>
                  </div>
                </div>
              </section>
            )
          })}
        </div> */}







      </div>
    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

// export function subMenuFilter (subMenu) {
//   const tag = subMenu.tag
//   if (!tag) {return true}
//
//   const now = new Date()
//   const day = now.getDay() // Sunday - Saturday : 0 - 6
//   const hours = now.getHours() //0 to 23
//   const minutes = now.getMinutes() // 0 to 59
//   const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
//   const isBreakfast = (
//     (hours >= 0) && (hours < 11)
//   )
//   const isBrunch = (
//     (hours >= 0) && (hours < 15)
//   )
//   const isLunch = (
//     (hours >= 11) && (hours < 15)
//   )
//   const showLunchMenu = isWeekday && isLunch
//
//   const isDayBreakfast = tag.indexOf('weekday breakfast specials') !== -1 // m-f 6am - 11am
//   const isEndBreakfast = tag.indexOf('weekend breakfast specials') !== -1 // sat-sun 6am - 3pm
//   const isDayLunch =     tag.indexOf('weekday lunch specials') !== -1     // m-f 11am-3pm
//
//   if (isDayBreakfast && isWeekday && isBreakfast) { return true }
//   if (isEndBreakfast && !isWeekday && isBrunch) { return true }
//   if (isDayLunch && isWeekday && isLunch) {return true}
//   // if (isDinnerSubmenu && !showLunchMenu) { return true }
//
//   return false
// }
